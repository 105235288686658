import React, { useState, useEffect } from "react";
import "assets/styles/pages/sidebar.scss";
import "assets/styles/pages/dashboardstatus.scss";
import { Link } from "gatsby";
import treeicon from "assets/images/admin/tree-icon.png";
import breadcumbarrow from "assets/images/admin/breadcumb-arrow-left.png";
import plantedBy from "assets/images/admin/plantedBy.png";
import back from "assets/images/admin/back.svg";
import "react-pro-sidebar/dist/css/styles.css";
import "assets/styles/pages/customheader.scss";
import "assets/styles/pages/sidebar.scss";
import "assets/styles/pages/dashboardstatus.scss";
import "react-pro-sidebar/dist/css/styles.css";
import "assets/styles/pages/customheader.scss";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import closesmall from "assets/images/icons/close-small-icon.svg";
import { isMobile } from "react-device-detect";
import imageCompression from "browser-image-compression";
import { compressOptions } from "assets/data/imageCompress";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import AdminService from "services/admin/admin.service";
import PlantationListDetails from "services/plantation/plantation.service";
import { useQueryParam, NumberParam } from "use-query-params";

// import PropTypes from "prop-types";

export default function Viewgreenassetpage() {
  const [adminId] = useQueryParam("id", NumberParam);

  const adminApi = new AdminService();
  const ds = new PlantationListDetails();

  const [selectedSpeciesData, setSelectedSpeciesData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [sizeError, setSizeError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [fileError, setFileError] = useState([false]);
  const [signedList, setSignedList] = useState([]);
  const [filesDataList, setFilesDataList] = useState([]);
  const [deletedImage, setDeletedImage] = useState();
  const [trackDelete, setTrackDelete] = useState(false);
  const [trackId, setTrackId] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [assetData, setAssetData] = useState("");
  const [loading, setLoading] = useState(false);

  const viewGreenAsset = async (id) => {
    setLoading(true);
    await adminApi
      .getGreenAsset(id)
      .then((resData) => {
        if (resData?.status === 200) {
          setLoading(false);
          setAssetData(resData?.data);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          toast.error(error);
        }
      });
  };

  const fileLoad = async (e) => {
    let testFile = e.target.files[0];
    // const reader = new FileReader();
    let fileExtension = testFile.type.split("/").pop();

    const compressedFile = await imageCompression(testFile, compressOptions);

    if (
      fileExtension !== "png" &&
      fileExtension !== "jpg" &&
      fileExtension !== "jpeg" &&
      fileExtension !== "gif"
    ) {
      setTypeError(true);
      setFileError(false);
    } else if (compressedFile.size > 2e6) {
      setSizeError(true);
      setFileError(false);
      setTypeError(false);
    } else {
      const name = e.target.files[0].name;
      const lastDot = name.lastIndexOf(".");

      const fileName = name.substring(0, lastDot);
      const file = e.target.files[0];
      const filetype = e.target.files[0].type;
      fileExtension = file.type.split("/").pop();

      let imgData = {
        fileName: fileName,
        fileExtension: fileExtension,
        fileType: filetype,
      };
      const filesData = [...filesList, compressedFile];
      const signedData = [...signedList, imgData];
      setFilesList(filesData);
      setFilesDataList([...filesDataList, e.target.files[0]]);
      setSignedList(signedData);
      setFileError(false);
      setSizeError(false);
      setLoading(false);
    }
  };

  const imageRemove = (e) => {
    const myArray = filesList.filter(function (obj) {
      return obj.name !== e.currentTarget.id;
    });
    setFilesList(myArray);
    const infoArray = signedList.filter(function (obj) {
      const lastDot = e.currentTarget.id.lastIndexOf(".");
      const fileName = e.currentTarget.id.substring(0, lastDot);
      return obj.fileName !== fileName;
    });
    setSignedList(infoArray);
  };

  const imageRemoveApi = () => {
    setLoading(true);

    const deleteIdData = {
      trackingDetailId: assetData?.id,
      imageLogId: trackId,
    };
    adminApi
      .deleteGreenAssetImage(deleteIdData)
      .then(() => {
        setLoading(false);

        toast.success("Imaged Removed successfully");
        setDeletedImage(deleteIdData);
        setTrackDelete(false);
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  const deleteOpen = (deleteId) => {
    setTrackId(deleteId);
    setTrackDelete(true);
  };
  const trackClose = () => {
    setTrackDelete(false);
    setTrackId("");
  };

  const finalCall = (images) => {
    const uploadImagePayload = { pictureDetailRequest: images };
    adminApi
      .uploadGreenAssetImage(uploadImagePayload, assetData?.id)
      .then(() => {
        setLoading(false);
        toast.success("Image Uploaded successfully");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const imageUploadCall = async (imgData) => {
    setLoading(true);
    let pathImages = [];
    const resultsData = await imgData.map((item, index) => {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/octet-stream");
      myHeaders.append("x-goog-acl", "public-read");

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: filesList[index],
        redirect: "follow",
      };
      setLoading(true);
      return fetch(item.signedUrl, requestOptions).then(() => {
        var obj = {};
        obj["pictureUrl"] = imgData[index].objectPath;
        pathImages.push(obj);
      });
    });
    return Promise.all(resultsData)
      .then(() => {
        finalCall(pathImages);
      })
      .catch(() => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const saveImg = async () => {
    setLoading(true);
    const plantationResponse = await ds.imagesSignedUrl({
      signedUrlDetails: signedList,
    });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }

    await imageUploadCall(plantationResponse.data.signedUrlDetails);
  };

  const imageUploadApi = () => {
    setLoading(true);
    saveImg();
  };

  useEffect(() => {
    if (filesList.length > 0) {
      setShowUpload(true);
    } else if (filesList.length === 0) {
      setShowUpload(false);
    }
  }, [filesList]);

  useEffect(() => {
    if (deletedImage?.imageLogId) {
      setAssetData({
        ...assetData,
        imageResource: assetData?.imageResource?.filter(
          (item) => deletedImage?.imageLogId !== item?.id
        ),
      });
    }
  }, [deletedImage]);

  const selectedSpecies = (selectedSpeciesId) => {
    setShowDetails(true);
    setSelectedSpeciesData(
      assetData?.assetSpeciesDetail?.filter((item) => {
        return (
          item?.species?.speciesName === selectedSpeciesId?.species?.speciesName
        );
      })
    );
  };

  useEffect(() => {
    if (adminId) {
      viewGreenAsset(adminId);
    }
  }, [adminId]);

  return (
    <>
      <div className="sidebar-content-wrapper">
        <div className="row">
          <div className="col-md-8">
            <div className="admin-breadcumb-item">
              <span>
                <Link className="" to="/admin/dashboardview/">
                  <img src={treeicon} alt="treeicon" />
                </Link>
              </span>

              <span>
                <img src={breadcumbarrow} alt="arrowicon" />
              </span>

              <h3> View Green Asset </h3>
            </div>
          </div>

          <div
            className="col-md-4"
            style={{ marginTop: "20px", paddingRight: "45px" }}
          >
            <Link
              to="/admin/greenassetsstatus"
              className="green-asset-btn fl-right"
            >
              <img src={back} alt="back" /> Back
            </Link>
          </div>
        </div>

        <div className="white-iitiative-wrapper">
          <div className="">
            <h3 className="sub-heading-title">{assetData?.siteName}</h3>
          </div>

          <div className="details-head-wrapper">
            <div className="dis-in-planed">
              <span className="dis-image-item">
                <img src={plantedBy} alt="treeRightIconGreen" />
              </span>
              <h5 className="planed-text">State :</h5>
              <span>{assetData?.attributeMap?.state}</span>
            </div>
            <div className="dis-in-planed">
              <span className="dis-image-item">
                <img src={plantedBy} alt="treeRightIconGreen" />
              </span>
              <h5 className="planed-text">Village/City :</h5>
              <span>{assetData?.attributeMap?.city}</span>
            </div>
            <div className="dis-in-planed">
              <span className="dis-image-item">
                <img src={plantedBy} alt="treeRightIconGreen" />
              </span>
              <h5 className="planed-text">Ashram Site Name :</h5>
              <span>{assetData?.siteName}</span>
            </div>
            <div className="dis-in-planed">
              <span className="dis-image-item">
                <img src={plantedBy} alt="treeRightIconGreen" />
              </span>
              <h5 className="planed-text">Site Area(in Acres) :</h5>
              <span>{assetData?.siteArea}</span>
            </div>
          </div>
          <div className="tree-card-wrapper">
            {assetData?.assetSpeciesDetail &&
              assetData?.assetSpeciesDetail.map((item) => {
                return (
                  <div
                    className={"tree-card"}
                    onClick={() => selectedSpecies(item)}
                  >
                    <h6 className="green-asset-card-title">
                      {item?.speciesCount}{" "}
                      {Number(item?.speciesCount) > 1 ? "Trees" : "Tree"}
                    </h6>
                    <p className="green-asset-date-time-text">
                      <b>{item?.species?.speciesName}</b>
                    </p>
                  </div>
                );
              })}
          </div>

          {showDetails && (
            <div>
              <div className="tree-details-title">
                <h3 className="sub-heading-title">Tree Details</h3>
              </div>

              <div className="tree-detail-table-wrapper">
                <table className="tree-detail-table">
                  <tr className="table-row">
                    <th className="head-cell" style={{ width: "13%" }}>
                      Tracking ID
                    </th>
                    <th className="head-cell" style={{ width: "15%" }}>
                      DBH (in cm)
                    </th>
                    <th className="head-cell" style={{ width: "15%" }}>
                      Height (in cm)
                    </th>
                    <th className="head-cell" style={{ width: "18%" }}>
                      Is the tree yeilding seeds (For Propagation)?
                    </th>
                    <th className="head-cell" style={{ width: "26%" }}>
                      Approximate yearly yeild from the tree (in INR)?
                    </th>
                    <th className="head-cell" style={{ width: "13%" }}>
                      Year Of Plantation
                    </th>
                  </tr>
                  {selectedSpeciesData &&
                    selectedSpeciesData?.[0]?.assetSpeciesTrackingDetail?.map(
                      (item) => {
                        return (
                          <tr className="table-row">
                            <td className="body-cell">{item.trackingId}</td>
                            <td className="body-cell">
                              {item.attributeMap.girth}
                            </td>
                            <td className="body-cell">
                              {item.attributeMap.height}
                            </td>
                            <td className="body-cell">
                              {item.isYielding === true ? "Yes" : "No"}
                            </td>
                            <td className="body-cell">
                              {item.yearlyYieldAmount}
                            </td>
                            <td className="body-cell">
                              {item.yearOfPlantation}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </table>
              </div>
            </div>
          )}
          <div className="addimage-wrapper">
            <h3 className="title-item"> ADDED IMAGES</h3>

            <div className="dis-flex add-more-img-posi">
              <div>
                {assetData?.imageResource &&
                  assetData?.imageResource?.map((data) => {
                    return (
                      <div className="recent-plantation-section-plantation">
                        <div
                          className="img-wrapper img_wrp hand-pointer"
                          key={data}
                        >
                          <SimpleReactLightbox>
                            <SRLWrapper>
                              <img
                                src={data.imageUrl}
                                className="img-fluid"
                                alt=""
                                width="140px"
                                height="100px"
                              />
                            </SRLWrapper>
                          </SimpleReactLightbox>
                          <div>
                            <span className="small-close small-close-images hand-pointer close">
                              <div
                                id={data.name}
                                onClick={() => {
                                  deleteOpen(data?.id);
                                }}
                              >
                                <img
                                  src={closesmall}
                                  className="img-fluid"
                                  alt="Close small"
                                />{" "}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div>
                <div className="dis-flex add-more-img-posi">
                  {filesList &&
                    filesList.map((fileData, index) => {
                      return (
                        <div className="img_wrp-container" key={index + 1}>
                          <span className="small-close small-close-images hand-pointer close">
                            <div id={fileData.name} onClick={imageRemove}>
                              {" "}
                              <img
                                src={closesmall}
                                className="img-fluid"
                                alt="Close small"
                              />{" "}
                            </div>
                          </span>
                          <SimpleReactLightbox>
                            <SRLWrapper>
                              <img
                                src={URL.createObjectURL(fileData)}
                                className="img-fluid"
                                alt="Add"
                                width="150px"
                                height="150px"
                              />
                            </SRLWrapper>
                          </SimpleReactLightbox>
                        </div>
                      );
                    })}
                  <div className="border-items">
                    <span className="plus-icon-green btn-file">
                      <i className="icon icon-plus-lg plus-symbol-custom-icon"></i>
                      <input
                        type="file"
                        multiple
                        onChange={fileLoad}
                        disabled={filesList.length === 5}
                        accept="image/png, image/gif, image/jpeg"
                      />
                      Add more
                    </span>
                  </div>
                </div>
                <div className={isMobile ? `` : `margin-admin-10`}>
                  {sizeError === true ? (
                    <span className="form-error">
                      Please upload a file smaller than 2 MB
                    </span>
                  ) : null}
                  {typeError === true ? (
                    <span className="form-error">
                      File does not support. You must use .png or .jpg
                    </span>
                  ) : null}
                  {fileError === true ? (
                    <span className="form-error">Add at least one Image</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {showUpload && (
            <button
              type="button"
              className="btn btn-approve save-width"
              onClick={() => imageUploadApi()}
            >
              {" "}
              Upload{" "}
            </button>
          )}
          {assetData?.kmlUrl && (
            <div>
              <div className="tree-details-title">
                <h3 className="sub-heading-title">KML Location</h3>
              </div>
              <iframe
                // src="https://www.google.com/maps/d/embed?mid=1Vq_Ilf6NvbFQ3qozqOEZikWziz-EDeQ&ehbc=2E312F"
                src={assetData?.kmlUrl}
                width="960"
                height="480"
              ></iframe>
            </div>
          )}
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <Modal show={trackDelete} centered>
        <Modal.Header closeButton onClick={trackClose}>
          <Modal.Title>Delete Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackClose}
          >
            No
          </Button>
          <Button variant="primary" onClick={() => imageRemoveApi()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}

// Viewgreenassetpage.propTypes = {
//   data: PropTypes.objectOf(PropTypes.object()).isRequired,
// };
