import React from "react";

import AdminLayout from "../../adminlayout/index";

import SEO from "components/seo/index";

import Viewgreenassetpage from "components/admin/greenAsset/viewgreenassetpage";

// import PropTypes from "prop-types";

const Donorsview = ({ location }) => {
  const dataValue =
    location.state && location.state.assetData !== undefined
      ? location.state.assetData
      : null;

  return (
    <>
      <SEO title="Forests By Heartfulness admin" />
      <AdminLayout>
        <div>
          <Viewgreenassetpage data={dataValue} />
        </div>
      </AdminLayout>
    </>
  );
};

// Donorsview.propTypes = {
//   location: PropTypes.objectOf(PropTypes.object()).isRequired,
// };

export default Donorsview;
